import { useEffect } from 'react'
import { Spin } from 'antd'

import { useAuth } from 'src/auth/authProvider'
import config from 'src/config/app'

const Logout = () => {
  const { clearAccount } = useAuth()

  useEffect(() => {
    clearAccount()
    window.location.href = `${config.platform.auth}`
  }, [])

  return (
    <>
      <div className="allCenter">
        <Spin tip="Cerrando sesión..." />
      </div>
    </>
  )
}

export default Logout
